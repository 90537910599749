import React from "react"
import Navbar from "./global-components/navbar"
import PageHeader from "./global-components/page-header"
import ContactInfo from "./section-components/contact-info"
import ContactForm from "./section-components/contact-form"
import Map from "./section-components/map"
import CallToActionV1 from "./section-components/call-to-action-v1"
import Footer from "./global-components/footer"

const ContactV1 = () => {
  return (
    <div>
      <Navbar />
      <PageHeader
        headertitle="Contact Us"
        subheader="Contact"
        image="/assets/img/banners/architect-engineer-shaking-hands-other-hand-at-con-2022-01-28-15-44-34-utc.webp"
      />
      <ContactInfo />
      <ContactForm />
      <Map />
      <CallToActionV1 />
      <Footer />
    </div>
  )
}

export default ContactV1
