import React from "react"
import Navbar from "./global-components/navbar"
import PageHeader from "./global-components/page-header"
import ServiceDetails from "./section-components/service-details"
import CallToActionV1 from "./section-components/call-to-action-v1"
import Footer from "./global-components/footer"

const Project_Two = () => {
  return (
    <div>
      <Navbar />
      <PageHeader
        headertitle="Hellas Cakes"
        subheader="322 Lennox St, Richmond"
        image="/assets/img/banners/two-professional-engineers-creating-a-new-building-2022-03-09-03-14-47-utc.webp"
      />
      <ServiceDetails
        imageOne={"/assets/img/hellas-cakes.webp"}
        imageTwo={"/assets/img/keep/hellas-2.webp"}
        imageThree={"/assets/img/keep/hellas-3.webp"}
        textOne={
          "Our team recently completed a full renovation of a popular café in the bustling inner city. The client was seeking a fresh, modern look that would attract a younger, hipster crowd, as well as provide a comfortable and inviting space for regulars. Our team got to work, creating a design that incorporated a mix of vintage and industrial elements, with a focus on exposed brick, metal accents, and natural wood finishes. The project involved a complete overhaul of the existing space, including the installation of new flooring, lighting plumbing systems and converting the first floor into a modern office interior as well as the creation of a custom bar seating area."
        }
        textTwo={
          "The end result was a café that has been praised for its trendy and inviting atmosphere. The new décor has attracted a younger crowd and has given the café a much-needed boost in popularity. Our team paid close attention to the client's budget, sourcing unique, reclaimed materials to keep costs down while still delivering a high-quality renovation. The client was particularly impressed with our attention to detail and our ability to deliver a unique and functional space within the constraints of the budget. With its stylish new look and comfortable atmosphere, the café has become a popular destination for coffee lovers and foodies alike."
        }
      />
      <CallToActionV1 />
      <Footer />
    </div>
  )
}

export default Project_Two
