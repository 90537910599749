import React, { Component } from "react"
import { Link } from "react-router-dom"

class BlogSlider extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/"
    let customClass = this.props.customClass ? this.props.customClass : ""
    let sectionClass = this.props.sectionClass ? this.props.sectionClass : ""
    return (
      <div className={"ltn__blog-area pt-115--- pb-70 go-top " + sectionClass}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className={"section-subtitle ltn__secondary-color " + customClass}>
                  View Our Past Works
                </h6>
                <h1 className="section-title">Past Projects</h1>
              </div>
            </div>
          </div>
          <div className="row  ltn__blog-slider-one-active slick-arrow-1 ltn__blog-item-3-normal">
            {/* Blog Item */}
            <div className="col-lg-12">
              <div className="ltn__blog-item ltn__blog-item-3">
                <div className="ltn__blog-img">
                  <Link to="/project-1">
                    <img
                      style={{ maxHeight: "500px" }}
                      src={publicUrl + "assets/img/21-yarra-st-renovation.webp"}
                      alt="#"
                    />
                  </Link>
                </div>
                <div className="ltn__blog-brief">
                  <div className="ltn__blog-meta">
                    <ul>
                      <li className="ltn__blog-author">
                        <Link to="/project-1">
                          <i className="far fa-user" />
                          Winnertac Renovations
                        </Link>
                      </li>
                      <li className="ltn__blog-tags">
                        <Link to="/blog-grid">
                          <i className="fas fa-tags" />
                          Custom FitOut
                        </Link>
                        <Link to="/blog-grid">
                          <i className="fas fa-tags" />
                          Remodeling
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <h3 className="ltn__blog-title">
                    <Link to="/project-1">21 Yarra St, Sth Melbourne</Link>
                  </h3>
                  <div className="ltn__blog-meta-btn">
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <i className="far fa-calendar-alt" />
                          2015
                        </li>
                      </ul>
                    </div>
                    <div className="ltn__blog-btn">
                      <Link to="/project-1">View Project</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Blog Item */}
            <div className="col-lg-12">
              <div className="ltn__blog-item ltn__blog-item-3">
                <div className="ltn__blog-img">
                  <Link to="/project-3">
                    <img
                      style={{ maxHeight: "500px" }}
                      src={publicUrl + "assets/img/bridge-rd-business-renovation.webp"}
                      alt="#"
                    />
                  </Link>
                </div>
                <div className="ltn__blog-brief">
                  <div className="ltn__blog-meta">
                    <ul>
                      <li className="ltn__blog-author">
                        <Link to="/project-3">
                          <i className="far fa-user" />
                          Winnertac Renovations
                        </Link>
                      </li>
                      <li className="ltn__blog-tags">
                        <Link to="/blog-grid">
                          <i className="fas fa-tags" />
                          Custom FitOut
                        </Link>
                        <Link to="/blog-grid">
                          <i className="fas fa-tags" />
                          Remodeling
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <h3 className="ltn__blog-title">
                    <Link to="/project-3">312 Bridge Rd, Richmond</Link>
                  </h3>
                  <div className="ltn__blog-meta-btn">
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <i className="far fa-calendar-alt" />
                          2013
                        </li>
                      </ul>
                    </div>
                    <div className="ltn__blog-btn">
                      <Link to="/project-3">View Project</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            {/* Blog Item */}
            <div className="col-lg-12">
              <div className="ltn__blog-item ltn__blog-item-3">
                <div className="ltn__blog-img">
                  <Link to="/project-2">
                    <img
                      style={{ maxHeight: "500px" }}
                      src={publicUrl + "assets/img/hellas-cakes.webp"}
                      alt="#"
                    />
                  </Link>
                </div>
                <div className="ltn__blog-brief">
                  <div className="ltn__blog-meta">
                    <ul>
                      <li className="ltn__blog-author">
                        <Link to="/team-details">
                          <i className="far fa-user" />
                          Winnertac Renovations
                        </Link>
                      </li>
                      <li className="ltn__blog-tags">
                        <Link to="/blog-grid">
                          <i className="fas fa-tags" />
                          Custom FitOut
                        </Link>
                        <Link to="/blog-grid">
                          <i className="fas fa-tags" />
                          Remodeling
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <h3 className="ltn__blog-title">
                    <Link to="/project-2">322 Lennox St, Richmond</Link>
                  </h3>
                  <div className="ltn__blog-meta-btn">
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-date">
                          <i className="far fa-calendar-alt" />
                          2018
                        </li>
                      </ul>
                    </div>
                    <div className="ltn__blog-btn">
                      <Link to="/project-2">View Project</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BlogSlider
