import React from "react"
import { Link } from "react-router-dom"

function FeaturesV1(customClass) {
  let publicUrl = process.env.PUBLIC_URL + "/"

  return (
    <div className={customClass}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area ltn__section-title-2--- text-center">
              <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                Our Services
              </h6>
              <h1 className="section-title">Our Main Focus</h1>
            </div>
          </div>
        </div>
        <div className="row ltn__custom-gutter--- justify-content-center go-top">
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
              <div className="ltn__feature-icon">
                <img src={publicUrl + "assets/img/icons/icon-img/21.webp"} alt="#" />
              </div>
              <div className="ltn__feature-info">
                <h3 style={{ whiteSpace: "nowrap" }}>
                  <Link to="/contact">Commercial Remodeling</Link>
                </h3>
                <p>
                  Enhance the functionality of your business with the help of our expert commercial
                  renovation services, and let us assist you in creating your ideal workplace that
                  meets aspirations.
                </p>
                <Link className="ltn__service-btn" to="/contact">
                  Build with Confidence <i className="flaticon-right-arrow" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
              <div className="ltn__feature-icon">
                <img src={publicUrl + "assets/img/icons/icon-img/22.webp"} alt="#" />
              </div>
              <div className="ltn__feature-info">
                <h3>
                  <Link to="/contact">Building Consultancy</Link>
                </h3>
                <p>
                  Our team of experienced professionals is dedicated to ensuring your project is a
                  success. Inclusive of assistance with obtaining permits etc, and access to
                  architects and Engineers.
                </p>
                <Link className="ltn__service-btn" to="/contact">
                  Build with Confidence <i className="flaticon-right-arrow" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
              <div className="ltn__feature-icon">
                <img src={publicUrl + "assets/img/icons/icon-img/23.webp"} alt="#" />
              </div>
              <div className="ltn__feature-info">
                <h3>
                  <Link to="/contact">Commercial Fitouts</Link>
                </h3>
                <p>
                  We take pride in our ability to provide highly tailored commercial fitout
                  solutions that are specifically designed to elevate and enhance the overall
                  productivity of your business
                </p>
                <Link className="ltn__service-btn" to="/contact">
                  Build with Confidence <i className="flaticon-right-arrow" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default FeaturesV1
