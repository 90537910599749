import React, { Component } from "react"
import { Link } from "react-router-dom"

class CategoryV1 extends Component {
  render() {
    return (
      <div className="ltn__category-area ltn__product-gutter section-bg-1--- pt-115 pb-90 go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  Partnering for Seamless Outcomes
                </h6>
                <h1 className="section-title">Collaborative Solutions</h1>
              </div>
            </div>
          </div>
          <div className="row ltn__category-slider-active--- slick-arrow-1 justify-content-center">
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 text-center">
                <Link to="/contact">
                  <span className="category-icon">
                    <i className="flaticon-car" />
                  </span>
                  <span className="category-title">Stress-Free</span>
                  <span className="category-btn">
                    <i className="flaticon-right-arrow" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 text-center">
                <Link to="/contact">
                  <span className="category-icon">
                    <i className="flaticon-swimming" />
                  </span>
                  <span className="category-title">Quality Trades</span>
                  <span className="category-btn">
                    <i className="flaticon-right-arrow" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 text-center">
                <Link to="/contact">
                  <span className="category-icon">
                    <i className="flaticon-secure-shield" />
                  </span>
                  <span className="category-title">Timely Delivery</span>
                  <span className="category-btn">
                    <i className="flaticon-right-arrow" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 text-center">
                <Link to="/contact">
                  <span className="category-icon">
                    <i className="flaticon-stethoscope" />
                  </span>
                  <span className="category-title">Happy Customers</span>
                  <span className="category-btn">
                    <i className="flaticon-right-arrow" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 text-center">
                <Link to="/contact">
                  <span className="category-icon">
                    <i className="flaticon-book" />
                  </span>
                  <span className="category-title">Expert Guidance</span>
                  <span className="category-btn">
                    <i className="flaticon-right-arrow" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 text-center">
                <Link to="/contact">
                  <span className="category-icon">
                    <i className="flaticon-bed-1" />
                  </span>
                  <span className="category-title">Affordable Pricing</span>
                  <span className="category-btn">
                    <i className="flaticon-right-arrow" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 text-center">
                <Link to="/contact">
                  <span className="category-icon">
                    <i className="flaticon-home-2" />
                  </span>
                  <span className="category-title">Creative Designs</span>
                  <span className="category-btn">
                    <i className="flaticon-right-arrow" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="ltn__category-item ltn__category-item-5 text-center">
                <Link to="/contact">
                  <span className="category-icon">
                    <i className="flaticon-slider" />
                  </span>
                  <span className="category-title">Efficient Processes</span>
                  <span className="category-btn">
                    <i className="flaticon-right-arrow" />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CategoryV1
