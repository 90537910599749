import React, { Component } from "react"
import ReactDOM from "react-dom"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import HomeV5 from "./components/home-v5"
import Service from "./components/service"
import Error from "./components/404"
import Contact from "./components/contact"
import Project_One from "./components/project-1"
import Project_Two from "./components/project-2"
import Project_Three from "./components/project-3"

class Root extends Component {
  render() {
    return (
      <BrowserRouter>
        <div style={{ overflowX: "hidden" }}>
          <Switch>
            <Route exact path="/" component={HomeV5} />
            <Route exact path="/service" component={Service} />
            <Route exact path="/project-1" component={Project_One} />
            <Route exact path="/project-2" component={Project_Two} />
            <Route exact path="/project-3" component={Project_Three} />
            <Route exact path="/404" component={Error} />
            <Route exact path="/contact" component={Contact} />
          </Switch>
        </div>
      </BrowserRouter>
    )
  }
}

export default Root

ReactDOM.render(<Root />, document.getElementById("quarter"))
