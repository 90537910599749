import React, { Component } from "react"
import { Link } from "react-router-dom"

class AboutV1 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/"

    return (
      <div className="ltn__about-us-area pt-120 pb-90 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="about-us-img-wrap about-img-left">
                <img src={publicUrl + "assets/img/funky.webp"} alt="About Us" />
                <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3"></div>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                    About Us
                  </h6>
                  <h1 className="section-title">
                    Expert Commercial Renovation Solutions<span>.</span>
                  </h1>
                  <p>
                    We are a team of experienced commercial builders who specialize in renovations.
                    With years of experience and a commitment to excellence, we are dedicated to
                    bringing your vision to life. Whether you're looking to modernize your space or
                    increase functionality, we have the expertise to make it happen.
                  </p>
                </div>
                <ul className="ltn__list-item-half clearfix">
                  <li>
                    <i className="flaticon-home-2" />
                    Innovative Space Planning
                  </li>
                  <li>
                    <i className="flaticon-mountain" />
                    Enhanced Aesthetic Appeal
                  </li>
                  <li>
                    <i className="flaticon-heart" />
                    Improved Workplace Functionality
                  </li>
                  <li>
                    <i className="flaticon-secure" />
                    Tailored Construction Solutions for Your Business.
                  </li>
                </ul>
                <div className="ltn__callout bg-overlay-theme-05  mt-30">
                  <p>
                    "Empower Your Business to Reach New Heights with Our Expert Commercial
                    Renovation Solutions."
                  </p>
                </div>
                <div className="btn-wrapper animated go-top">
                  <Link to="/contact" className="theme-btn-1 btn btn-effect-1">
                    Enquire Today
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AboutV1
