import React, { Component } from "react"

class BannerV4 extends Component {
  render() {
    return (
      <div className="ltn__slider-area ltn__slider-2--- ltn__slider-6 section-bg-2">
        <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1 arrow-white">
          {/* ltn__slide-item */}
          <div
            className="ltn__slide-item ltn__slide-item-2--- ltn__slide-item-6 text-color-white bg-image bg-overlay-theme-black-60"
            data-bs-bg={"/assets/img/winnertac-construction.webp"}
          >
            <div className="ltn__slide-item-inner text-center">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 align-self-center">
                    <div className="slide-item-info">
                      <div className="slide-item-info-inner ltn__slide-animation">
                        <h4 className="slide-sub-title text-color-orange animated">
                          <span className="text-color-orange">
                            <i className="fas fa-home" />
                          </span>{" "}
                          Winnertac Renovations
                        </h4>
                        <h1 className="slide-title text-center text-uppercase animated ">
                          Expert Commercial Renovations Solutions
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BannerV4
