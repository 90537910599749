import React from "react"
import { Link } from "react-router-dom"

const PageHeader = ({ headertitle, subheader, customclass, image }) => {
  const headerTitle = headertitle
  const subHeader = subheader ? subheader : headerTitle
  const customClass = customclass ? customclass : ""

  return (
    <div
      className={`ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image ${customClass}`}
      data-bs-bg={image}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__breadcrumb-inner">
              <h1 className="page-title">{headerTitle}</h1>
              <div className="ltn__breadcrumb-list">
                <ul>
                  <li>
                    <Link to="/">
                      <span className="ltn__secondary-color">
                        <i className="fas fa-home" />
                      </span>{" "}
                      Home
                    </Link>
                  </li>
                  <li>{subHeader}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageHeader
