import React, { Component } from "react"

class ContactInfo extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/"

    return (
      <div className="ltn__contact-address-area mb-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <a href="mailto:bruno@winnertacrenovations.com.au">
                <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                  <div className="ltn__contact-address-icon">
                    <img src={publicUrl + "assets/img/icons/10.webp"} alt="" />
                  </div>
                  <h3>Email Address</h3>
                  <p>bruno@winnertacrenovations.com.au</p>
                </div>
              </a>
            </div>
            <div className="col-lg-4">
              <a href="tel:0418541421">
                <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                  <div className="ltn__contact-address-icon">
                    <img src={publicUrl + "assets/img/icons/11.webp"} alt="" />
                  </div>
                  <h3>Phone Number</h3>
                  <p>0418 541 421</p>
                </div>
              </a>
            </div>
            <div className="col-lg-4">
              <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                <div className="ltn__contact-address-icon">
                  <img src={publicUrl + "assets/img/icons/12.webp"} alt="" />
                </div>
                <h3>Office Address</h3>
                <p>Richmond, Victoria, Australia</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ContactInfo
