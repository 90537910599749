import React, { Component } from "react"
import { Link } from "react-router-dom"

class AboutV5 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/"

    return (
      <div className="ltn__about-us-area pb-115 go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 align-self-center">
              <div className="about-us-img-wrap ltn__img-shape-left  about-img-left">
                <img src={"/assets/img/312-bridge-rd.webp"} alt="" />
              </div>
            </div>
            <div className="col-lg-7 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                    About Us
                  </h6>
                  <h1 className="section-title">
                    The Leading Commercial Renovation Partner
                    <span>.</span>
                  </h1>
                  <p>
                    Our team of experienced professionals specializes in delivering top-quality
                    commercial renovations and fitouts.
                  </p>
                </div>
                <div className="about-us-info-wrap-inner about-us-info-devide---">
                  <p>
                    With a focus on collaboration and attention to detail, we work closely with our
                    clients to turn their visions into reality. From start to finish, we are
                    dedicated to ensuring the success of each project and delivering results that
                    exceed expectations. Whether it's a complete overhaul or a minor refresh, we
                    have the expertise and knowledge to turn your space into a functional and
                    efficient work environment.
                  </p>
                </div>
                <div className="btn-wrapper animated">
                  <Link to="/contact" className="theme-btn-1 btn btn-effect-1 text-uppercase">
                    Transform Your Workspace
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AboutV5
