import React, { Component } from "react"

class BlogSlider extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/"
    let customClass = this.props.customClass ? this.props.customClass : ""
    let sectionClass = this.props.sectionClass ? this.props.sectionClass : ""
    const suppliers = [
      {
        name: "Demar",
        logo: "assets/img/suppliers/demar.webp",
        url: "https://www.demar.com.au/",
      },
      {
        name: "Hanson Concrete",
        logo: "assets/img/suppliers/hanson.svg",
        url: "https://www.hanson.com.au/",
      },
      {
        name: "Reece plumbing supply Richmond",
        logo: "assets/img/suppliers/reece.png",
        url: "https://www.reece.com.au/",
      },
      {
        name: "Ungar Metals Chadstone",
        logo: "assets/img/suppliers/ungar.webp",
        url: "https://www.ungarmetals.com.au/",
      },
      {
        name: "Metro Bolts Clayton",
        logo: "assets/img/suppliers/metro bolts.webp",
        url: "https://www.metrobolts.com.au/",
      },
      {
        name: "Rexel Electrical supplies Richmond",
        logo: "assets/img/suppliers/rexel.png",
        url: "https://www.rexel.com.au/",
      },
      {
        name: "Trident Security (preston)",
        logo: "assets/img/suppliers/trident.jpg",
        url: "https://www.trisec.com.au/",
      },
      {
        name: "Harvey Norman Richmond appliances",
        logo: "assets/img/suppliers/harvey_norman.png",
        url: "https://www.harveynorman.com.au/",
      },
    ]
    return (
      <div className={`ltn__blog-area pt-115 pb-70 go-top ${sectionClass}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className={`section-title-area ltn__section-title-2--- text-center`}>
                <h6 className={`section-subtitle ltn__secondary-color ${customClass}`}>View Our</h6>
                <h1 className="section-title">Suppliers</h1>
              </div>
            </div>
          </div>
          <div className="row  d-flex align-items-center justify-content-center flex ltn__blog-slider-one-active slick-arrow-1 ltn__blog-item-3-normal">
            {suppliers.map((supplier) => (
              <div className="col-lg-12" key={supplier.name}>
                <div className="ltn__blog-item ltn__blog-item-3">
                  <div className="ltn__blog-img">
                    <a href={supplier.url} rel="nofollow">
                      <img
                        style={{
                          maxHeight: "100px",
                          maxWidth: "100%",
                          width: "auto",
                          height: "auto",
                        }}
                        src={supplier.logo}
                        alt={supplier.name}
                      />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default BlogSlider
