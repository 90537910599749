import React from "react"
import Navbar from "./global-components/navbar-v4"
import VideoV4 from "./section-components/video-v4"
import Counter from "./section-components/counter-v1"
import Featuresv1 from "./section-components/features-v1"
import Category from "./section-components/category-v1"
import Testimonial from "./section-components/testimonial-v1"
import CallToActionV1 from "./section-components/call-to-action-v1"
import Footer from "./global-components/footer"
import AboutV1 from "./section-components/about-v1"
import AboutV3 from "./section-components/about-v3"
import BannerV4 from "./section-components/banner-v4"
import BlogSlider from "./blog-components/blog-slider-logos"

const Home_V5 = () => {
  return (
    <div>
      <Navbar CustomClass="ltn__header-transparent gradient-color-2" />
      {/* <VideoV4 /> */}
      <BannerV4 />
      <AboutV1 />
      <BlogSlider />
      {/* <Counter /> */}
      <AboutV3 />
      <Featuresv1 customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---" />
      <Category />
      <Testimonial />
      <CallToActionV1 />
      <Footer />
    </div>
  )
}

export default Home_V5
