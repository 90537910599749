import React, { Component } from "react"
import { Link } from "react-router-dom"
import parse from "html-react-parser"

class AboutV3 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/"

    return (
      <div className="ltn__about-us-area pt-115 pb-100 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="about-us-img-wrap about-img-left">
                <img src={publicUrl + "assets/img/hellas-cakes.webp"} alt="About Us" />
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-30">
                  <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
                    About Us
                  </h6>
                  <h1 className="section-title">
                    Designing Innovative Workplaces for a Thriving Future.
                  </h1>
                  <p>
                    Elevate your local business with our expert commercial renovation solutions,
                    featuring energy-efficient design criteria such as modern high-standard
                    insulation, double-glazed windows, and solar installations. Partner with our
                    skilled team to transform your space, modernize your facilities, and improve
                    functionality, delivering a top-notch experience to your customers and employees
                    alike.
                  </p>
                </div>
                <div className="ltn__feature-item ltn__feature-item-3">
                  <div className="ltn__feature-icon">
                    <span>
                      <i className="flaticon-house-4" />
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h4>
                      <a href="service-details.html">The Perfect Renovation</a>
                    </h4>
                    <p>
                      Revolutionizing Commercial Spaces: Your Partner for Successful Renovations.
                    </p>
                  </div>
                </div>
                <div className="ltn__feature-item ltn__feature-item-3">
                  <div className="ltn__feature-icon">
                    <span>
                      <i className="flaticon-call-center-agent" />
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h4>
                      <a href="service-details.html">Attention to detail</a>
                    </h4>
                    <p>
                      We take pride in our work and always strive for the highest level of quality
                      in every project.
                    </p>
                  </div>
                </div>
                <div className="ltn__feature-item ltn__feature-item-3">
                  <div className="ltn__feature-icon">
                    <span>
                      <i className="flaticon-maps-and-location" />
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h4>
                      <a href="service-details.html">Communication</a>
                    </h4>
                    <p>
                      From the initial consultation to the final walk-through, we keep you informed
                      every step of the way.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AboutV3
