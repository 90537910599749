import React from "react"
import { Link, useLocation } from "react-router-dom"

function ServiceDetails({ textOne, textTwo, imageOne, imageTwo, imageThree, ProjectPictures }) {
  const route = useLocation()

  const ProjectList = [
    { icon: "fas fa-life-ring", title: "21 Yarra St", link: "/project-1" },
    { icon: "fas fa-life-ring", title: "Hellas Cakes", link: "/project-2" },
    { icon: "fas fa-life-ring", title: "312 Bridge Road", link: "/project-3" },
  ]

  return (
    <div className="ltn__page-details-area ltn__service-details-area mb-105">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="ltn__page-details-inner ltn__service-details-inner">
              <div className="ltn__blog-img">
                <img src={imageOne} alt="Richmond Professional Commercial Builder" />
              </div>
              <p>
                {" "}
                <span className="ltn__first-letter">
                  {ProjectList.find((project) => route.pathname === project.link).link.at(-1)}
                </span>
                {textOne}
              </p>

              <p>{textTwo}</p>
            </div>
            <div className="container">
              <div className="row">
                {ProjectPictures ? (
                  <>
                    {ProjectPictures.map((item, idx) => {
                      return (
                        <div key={idx} className="col-sm">
                          <img loading="lazy" src={item.img} alt={item.alt} />
                        </div>
                      )
                    })}{" "}
                  </>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <aside className="sidebar-area ltn__right-sidebar">
              {/* Menu Widget */}
              <div className="widget-2 ltn__menu-widget ltn__menu-widget-2 text-uppercase">
                <ul className="go-top">
                  {ProjectList.map((item) => {
                    return (
                      <li key={item.title} className={route.pathname === item.link ? "active" : ""}>
                        <Link to={item.link}>
                          {item.title}
                          <span>
                            <i className={item.icon} />
                          </span>
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
              {/* Newsletter Widget */}
              <div className="widget ltn__search-widget ltn__newsletter-widget">
                <h6 className="ltn__widget-sub-title">// subscribe</h6>
                <h4 className="ltn__widget-title">Get Newsletter</h4>
                <form action="#">
                  <input type="text" name="search" placeholder="Enter email.." />
                  <button type="submit">
                    <i className="fas fa-arrow-alt-circle-right" />
                  </button>
                </form>
                <div className="ltn__newsletter-bg-icon">
                  <i className="fas fa-envelope-open-text" />
                </div>
              </div>
              {/* Banner Widget */}
              <div className="widget ltn__banner-widget go-top">
                <Link to="/shop">
                  <img src={imageTwo} alt="21 Yarra St, front renovation" />
                  <img src={imageThree} alt="Richmond Builder Commercial renovation" />
                </Link>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceDetails
