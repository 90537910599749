import React, { Component } from "react"

class Testimonial extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/"

    return (
      <div
        className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-70"
        data-bs-bg={publicUrl + "assets/img/bg/20.jpg"}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  Our Testimonial
                </h6>
                <h1 className="section-title">Clients Feedback</h1>
              </div>
            </div>
          </div>
          <div className="row ltn__testimonial-slider-5-active slick-arrow-1">
            <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7">
                <div className="ltn__testimoni-info">
                  <p>
                    <i className="flaticon-left-quote-1" />
                    Winnertac Renovations did an excellent job of transforming my brand-new personal
                    training and exercise therapy studio. The attention to detail and expertise in
                    commercial renovation is evident. The team incorporated modern and
                    energy-efficient design criteria, creating a space that is both functional and
                    aesthetically pleasing.
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    <div className="ltn__testimoni-name-designation">
                      <h5>Hayden Thin</h5>
                      <label>Owner - Club Forma</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7">
                <div className="ltn__testimoni-info">
                  <p>
                    <i className="flaticon-left-quote-1" />
                    From the initial consultation to the final walkthrough, Winnertac Renovations
                    went above and beyond. Their team of experts brought creativity and attention to
                    detail to every aspect of the renovation process, ensuring that my vision for
                    the retail space was fully realized. The result is a stunning and has received
                    many compliments from customers and visitors alike.
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    <div className="ltn__testimoni-name-designation">
                      <h5>Peter Laliatos</h5>
                      <label>Owner - Hellas Cakes</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__testimonial-item ltn__testimonial-item-7">
                <div className="ltn__testimoni-info">
                  <p>
                    <i className="flaticon-left-quote-1" />
                    The team at Winnertac Renovations transformed my outdated office space into a
                    modern, functional working and clinical environment for my business. I couldn't
                    be happier with the results and would highly recommend their services to anyone
                    in need of their commercial renovation services and expertise. I rate them 10/10
                    everytime.
                  </p>
                  <div className="ltn__testimoni-info-inner">
                    <div className="ltn__testimoni-name-designation">
                      <h5>Ben Sutherland</h5>
                      <label>Owner - Spine Consultants</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*  */}
          </div>
        </div>
      </div>
    )
  }
}

export default Testimonial
