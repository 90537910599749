import React from "react"
import Navbar from "./global-components/navbar"
import PageHeader from "./global-components/page-header"
import ServiceDetails from "./section-components/service-details"
import CallToActionV1 from "./section-components/call-to-action-v1"
import Footer from "./global-components/footer"

const Project_One = () => {
  const ProjectPictures = [
    { img: "/assets/img/builds/21_yarra-2.webp", alt: "21 Yarra St Richmond Renovation" },
    { img: "/assets/img/builds/21_yarra-3.webp", alt: "21 Yarra St Richmond builder Renovated" },
    { img: "/assets/img/builds/21_yarra-4.webp", alt: "21 Yarra St Richmond Commerical Builder" },
    { img: "/assets/img/builds/21_yarra-5.webp", alt: "21 Yarra St Richmond Small Business Reno" },
    { img: "/assets/img/builds/21_yarra-6.webp", alt: "21 Yarra St Richmond Small Business Reno" },
    { img: "/assets/img/builds/21_yarra-7.webp", alt: "21 Yarra St Richmond Small Business Reno" },
  ]
  return (
    <div>
      <Navbar />
      <PageHeader
        headertitle="21 Yarra Street"
        subheader="21 Yarra St, South Melbourne"
        image="/assets/img/banners/team-construction-engineers-working-at-constructio-2022-10-27-21-08-11-utc.webp"
      />
      <ServiceDetails
        ProjectPictures={ProjectPictures}
        imageOne={"/assets/img/21-yarra-st-renovation.webp"}
        imageTwo={"/assets/img/keep/21-yarra-st-2.jpg"}
        imageThree={"/assets/img/keep/21-yarra-st-3.jpg"}
        textOne={
          "Our team recently completed a full renovation of a commercial office space in the heart of the city. The client was seeking a modern and functional workplace that would impress clients and boost employee morale. With a tight deadline, our team got to work quickly, creating a design that incorporated an open floor plan, ergonomic furniture, and a stunning feature wall. The project involved stripping the space back to its bare bones before installing new electrical and data systems, flooring, and wall finishes. The end result was a bright and modern workplace that exceeded the client's expectations and has been praised by employees and clients alike."
        }
        textTwo={
          "In addition to the aesthetic updates, our team also focused on improving the functionality of the space. We installed energy-efficient lighting and heating systems to reduce the building's carbon footprint, as well as smart storage solutions to maximize the available space. The client was particularly impressed with our attention to detail, as we went above and beyond to ensure that all aspects of the renovation were completed to the highest standard. The end result was a workplace that not only looks great but is also highly functional and sustainable, providing the client with a long-term investment that they can be proud of."
        }
      />
      <CallToActionV1 />
      <Footer />
    </div>
  )
}

export default Project_One
