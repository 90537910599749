import React from "react"
import Navbar from "./global-components/navbar"
import PageHeader from "./global-components/page-header"
import ServiceDetails from "./section-components/service-details"
import CallToActionV1 from "./section-components/call-to-action-v1"
import Footer from "./global-components/footer"

const Project_One = () => {
  const ProjectPictures = [
    {
      img: "/assets/img/builds/bridge/312_bridge_rd.webp",
      alt: "312 Bridge Richmond Small Business Reno",
    },
    {
      img: "/assets/img/builds/bridge/312_bridge_rd_2.webp",
      alt: "312 Bridge Richmond Renovation",
    },
    {
      img: "/assets/img/builds/bridge/312_bridge_rd_3.webp",
      alt: "312 Bridge Richmond builder Renovated",
    },
    {
      img: "/assets/img/builds/bridge/312_bridge_rd_4.webp",
      alt: "312 Bridge Richmond Commerical Builder",
    },
    {
      img: "/assets/img/builds/bridge/312_bridge_rd_5.webp",
      alt: "312 Bridge Richmond Small Business Reno",
    },
    {
      img: "/assets/img/builds/bridge/312_bridge_rd_6.webp",
      alt: "312 Bridge Richmond Building Consultancy",
    },
  ]
  return (
    <div>
      <Navbar />
      <PageHeader
        headertitle="312 Bridge Rd"
        subheader="312 Bridge Rd, Richmond"
        image="/assets/img/banners/engineer-working-setup-solar-panel-at-the-roof-top-2023-01-10-06-45-26-utc.webp"
      />
      <ServiceDetails
        ProjectPictures={ProjectPictures}
        imageOne={"/assets/img/keep/312-bridge-rd.webp"}
        imageTwo={"/assets/img/keep/312-bridge-rd-2.webp"}
        imageThree={"/assets/img/keep/312-bridge-rd-4.webp"}
        textOne={
          "Recently, our team completed a full renovation of a well-known commercial building in the bustling entertainment district of the city. The project's goal was to create a fresh, contemporary look that would appeal to both locals and tourists while improving the space's functionality. To achieve this, we designed an open floor plan, updated the bar and kitchen area, and introduced new furnishings throughout the venue. The owner/client had asked us to assist him with the removal of some substantial old brick load bearing walls severely restricting the usable area of the lower rear floor area of the building. These walls had rather large fireplaces with brick flues continuing up to the roof, possibly and old foundry from the early 1900’s. The works requiring considerable input from our structural engineer? All in all, a rather complicated job with limited space, with some interesting engineering problems that had to be overcome, all without any damage or cracking to the existing structure. Removal of all the old brick walls and fire places was facilitated with structural steel support on new foundations being implemented in lieu of the walls allowing for a clear span area. Our team performed a complete overhaul of the space, including the installation of new flooring, lighting, and electrical systems, and created a stunning feature wall."
        }
        textTwo={
          "The end result was a venue that has been praised for its modern, sophisticated atmosphere, attracting a new clientele and giving the venue a much-needed boost in popularity. Throughout the renovation process, we remained mindful of the client's budget, utilizing cost-effective materials without compromising on the quality of the final product. The client appreciated our attention to detail, as we went above and beyond to ensure that every aspect of the renovation was completed to the highest standard. With its new look and improved functionality, the venue is now a top destination for locals and tourists alike, offering a unique and enjoyable dining and entertainment experience."
        }
      />
      <CallToActionV1 />
      <Footer />
    </div>
  )
}

export default Project_One
