import React, { useState } from "react"

const ContactForm = () => {
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [formMessage, setFormMessage] = useState("")

  const handleSubmit = async (event) => {
    event.preventDefault()

    const formValues = {
      name: name,
      phone: phone,
      email: email,
      message: message,
    }

    // Send the form data using fetch
    fetch("/api/mailer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formValues),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Oops! An error occurred and your message could not be sent.")
        }
        return response.text()
      })
      .then((response) => {
        // Show success message
        setFormMessage(response)
        setName("")
        setPhone("")
        setEmail("")
        setMessage("")
      })
      .catch((error) => {
        // Show error message
        setFormMessage(error.message)
      })
  }

  return (
    <div className="ltn__contact-message-area mb-120 mb--100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__form-box contact-form-box box-shadow white-bg">
              <h4 className="title-2">Get A Quote</h4>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-item input-item-name ltn__custom-icon">
                      <input
                        type="text"
                        name="name"
                        value={name}
                        placeholder="Enter your name"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-item input-item-phone ltn__custom-icon">
                      <input
                        type="text"
                        name="phone"
                        value={phone}
                        placeholder="Enter phone number"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-item input-item-email ltn__custom-icon">
                      <input
                        type="email"
                        name="email"
                        value={email}
                        placeholder="Enter email address"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-item input-item-textarea ltn__custom-icon">
                  <textarea
                    name="message"
                    value={message}
                    placeholder="Enter message"
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>

                <div className="btn-wrapper mt-0">
                  <button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">
                    Send Enquiry
                  </button>
                </div>
                <p className="text-color-green mb-0 mt-20">{formMessage}</p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactForm
