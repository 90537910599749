import React from "react"
import Navbar from "./global-components/navbar"
import PageHeader from "./global-components/page-header"
import AboutV5 from "./section-components/about-v5"
import BlogSlider from "./blog-components/blog-slider-v1"
import CallToActionV1 from "./section-components/call-to-action-v1"
import Footer from "./global-components/footer"
import FeaturesV1 from "./section-components/features-v1"

const Service_V1 = () => {
  return (
    <div>
      <Navbar />
      <PageHeader
        headertitle="What We Do"
        subheader="Service"
        image="/assets/img/banners/group-of-young-businesspeople-working-together-at-2022-12-16-21-19-49-utc.webp"
      />
      <AboutV5 />
      <FeaturesV1 customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---" />

      <BlogSlider sectionClass="pt-120" />
      <CallToActionV1 />
      <Footer />
    </div>
  )
}

export default Service_V1
